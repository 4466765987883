<template v-if="formActive">

  <div class="d-flex header-part-1">
    <div class="w-50">
      <div class="w-50" v-if="hasUnits">
        <FormItem v-model="chosenPortId" :options="voyage.ports" idField="code" nameField="name_units" type="select" @onchange="setPort" />
      </div>
      <div class="d-flex gap-1 pt-2">
        <button class="col-3 btn btn-sm btn-primary" type="button" @click="allocOpen(true)">
          {{ $t('operations.update_alloc')  }}
        </button>
        <button class="col-3 btn btn-sm btn-primary" type="button" @click="closingOpenDefault()">
          {{ $t('operations.open_close')  }}
        </button>
      </div>
      <div id="total-labels">
        <div>{{ $t('overview.vp_waiting') }}</div>
        <div>{{ $t('operations.date_delivered') }}</div>
        <div>{{ $t('overview.vp_firm') }}</div>
        <div>{{ $t('overview.vp_permis') }}</div>
        <div>{{ $t('operations.date_loaded') }}</div>
      </div>
    </div>
    <div class="w-50" v-if="hasUnits">
      <ul class="nav nav-tabs mb-3" id="statsTab" role="tablist">
          <li class="nav-item" role="presentation">
              <button class="nav-link active" id="this-tab" data-bs-toggle="tab" data-bs-target="#this" type="button" role="tab" aria-controls="this" aria-selected="true">{{chosenPort.name + ' stats'}}</button>
          </li>
          <li class="nav-item" role="presentation">
              <button class="nav-link" id="all-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="all">{{ $t('operations.full_stats')}}</button>
          </li>
          <li class="pt-2 ps-2 flex-fill text-end">
              <button class="btn btn-secondary btn-sm" type="button" @click="showCBM = !showCBM">
                <span v-show="!showCBM">m<sup>2</sup> <i class="fa fa-arrow-right"></i> cbm</span>
                <span v-show="showCBM">cbm <i class="fa fa-arrow-right"></i> m<sup>2</sup> </span>
              </button>
          </li>
      </ul>
      <div class="tab-content" id="statsTabContent">
        <div class="tab-pane fade show active" id="this" role="tabpanel" aria-labelledby="this-tab">
          <div class="row port-stats">
            <div class="col-auto px-1" v-for="(portStat, index) in chosenPort.totals" :key="index">
              <div> {{ this.commgroups[index - 1] ? this.commgroups[index - 1].name : $t('overview.total') }} </div>
              <div v-for="(statusId, statusIndex) in statusIds" :key="statusIndex">
                <span class="fw-bold"> {{ portStat[statusId].units }} </span>
                <span v-if="showCBM">({{ getVolumeRounded(portStat[statusId].volume, portStat[statusId].height) }} m<sup>3</sup>)</span>
                <span v-else>({{ getVolumeRounded(portStat[statusId].volume) }} m<sup>2</sup>)</span>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="all" role="tabpanel" aria-labelledby="all-tab">
          <div class="row port-stats">
            <div class="col-auto px-1" v-for="(portStat, index) in fillVoyageStats" :key="index">
              <div> {{ this.commgroups[index - 1] ? this.commgroups[index - 1].name : $t('overview.total') }} </div>
              <div v-for="(statusId, statusIndex) in statusIds" :key="statusIndex">
                <span class="fw-bold"> {{ portStat[statusId].units }} </span>
                <span v-if="showCBM">({{ getVolumeRounded(portStat[statusId].volume, portStat[statusId].height) }} m<sup>3</sup>)</span>
                <span v-else>({{ getVolumeRounded(portStat[statusId].volume) }} m<sup>2</sup>)</span> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-50" v-else>
      <div class="row port-stats px-1" style="position: relative; top: 52px">
        <div class="col-auto">
          <div> {{ $t('overview.total') }} </div>
          <div v-for="n in statusIds" :key="n"><span class="fw-bold"> 0 </span> <span>(0 m<sup>2</sup>)</span> </div>
        </div>
      </div>
    </div>
  </div>

  <template v-if="hasUnits">
  <ul class="nav nav-tabs mb-3  header-part-2" id="myTab" role="tablist">
    <li class="nav-item" role="presentation" v-for="port in chosenPort.pods" :key="port.id">
      <button class="nav-link" :id="'tab-'+port.id" data-bs-toggle="tab" :data-bs-target="'#page-'+port.id" type="button" role="tab" :aria-controls="'page-'+port.id" 
             @click="chosenDischarge = port.code; getUnitData(chosenPort, port)"> <span>{{ port.code }}</span> &ensp; <span class="badge">{{port.unit_count}}</span>
      </button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade" v-for="port in chosenPort.pods" :key="port.id" :id="'page-'+port.id" role="tabpanel" :aria-labelledby="'tab-'+port.id">

      <div class="header-part-3">
        <p>{{ $t('operations.actial_space_av', [(chosenPort.code + ' - ' + port.code)]) }}</p>
        <div class="row gap-5">
          <div class="col-auto" v-show="perCommodity" v-for="allocation in chosenPort.pods[port.code].allocation" :key="allocation.id">
            <div class="fw-bold">{{ allocation.name }} &ensp; <i :class="'clickable far fa-lock' + (checkClosing(port, allocation) ? '' : '-open')" @click="closingOpen(true, port, allocation)"></i></div>
            <meter min="0" :max="calcSpace(allocation, 1)" :value="calcAllocation(allocation)" :low="calcSpace(allocation, .33)" :high="calcSpace(allocation, .67)" optimum="0"></meter>
          </div>
          <div class="col-auto" v-show="!perCommodity">
            <div class="fw-bold">{{ $t('overview.total') }} &ensp; <i :class="'clickable far fa-lock' + (checkClosing(port, null) ? '' : '-open')" @click="closingOpen(true, port, null)"></i></div>
            <meter min="0" :max="calcSpace(null, 1)" :value="calcAllocation(null)" :low="calcSpace(null, .33)" :high="calcSpace(null, .67)" optimum="0"></meter>
          </div>
        </div>
      </div>

      <template v-if="voyage.units[chosenPortId]">

        <ul class="nav nav-tabs my-3 header-part-4" id="unitsTab" role="tablist">
          <li class="nav-item" role="presentation">
              <button class="nav-link active" :id="'unit-tab-1-'+port.id" data-bs-toggle="tab" :data-bs-target="'#utab-'+port.id" type="button" role="tab" aria-controls="utab" aria-selected="true" @click="setUnitTab(0)">
                {{$t('overview.vp_waiting')}} &ensp; <span class="badge">{{voyage.units[chosenPortId][port.code] ? voyage.units[chosenPortId][port.code].filter(item => item.status == 1 && !item.inert).length : '0'}}</span>
              </button>
          </li>
          <li class="nav-item" role="presentation">
              <button class="nav-link" :id="'unit-tab-2-'+port.id" data-bs-toggle="tab" :data-bs-target="'#utab-'+port.id" type="button" role="tab" aria-controls="utab" @click="setUnitTab(1)">
                {{$t('overview.vp_firm')}} &ensp; <span class="badge">{{voyage.units[chosenPortId][port.code] ? voyage.units[chosenPortId][port.code].filter(item => item.status == 2 && !item.inert).length : '0'}}</span>
              </button>
          </li>
          <li class="nav-item" role="presentation">
              <button class="nav-link" :id="'unit-tab-3-'+port.id" data-bs-toggle="tab" :data-bs-target="'#utab-'+port.id" type="button" role="tab" aria-controls="utab" @click="setUnitTab(2)">
                {{$t('overview.vp_permis')}} &ensp; <span class="badge">{{voyage.units[chosenPortId][port.code] ? voyage.units[chosenPortId][port.code].filter(item => item.status == 3 && !item.inert).length : '0'}}</span>
              </button>
          </li>
          <li class="nav-item" role="presentation">
              <button class="nav-link" :id="'unit-tab-4-'+port.id" data-bs-toggle="tab" :data-bs-target="'#utab-'+port.id" type="button" role="tab" aria-controls="utab" @click="setUnitTab(3)">
                {{$t('operations.date_loaded')}} &ensp; <span class="badge">{{voyage.units[chosenPortId][port.code] ? voyage.units[chosenPortId][port.code].filter(item => item.status == 4 && !item.inert).length : '0'}}</span>
              </button>
          </li>
        </ul>

        <div class="header-part-5">
          <div class="row mb-3">
            <div class="col-1p5 fw-bold" style="padding: .3rem 0 0 .75rem">{{ $t('form.selected', [$tc('navigation.units', 2)]) }} {{selectedUnitCount}}</div>
            <button type="button" class="btn btn-sm btn-primary" style="width: 5%" @click="selectAll">{{$t('form.all')}}</button>
            <div class="col-2">
              <button class="btn btn-sm btn-primary w-100" type="button" @click="bulkActionUnits(port, 'status_id', -1)" v-show="selectedUnitTab > 1" :disabled="selectedUnitCount == 0 || voyage.cannot_permis">
                &lt;&nbsp;{{ ' ' + $t('operations.move_to_x', [ selectedUnitTabLabels[selectedUnitTab - 1] ]) }}
              </button>
            </div>
            <div class="col-2">
              <button class="btn btn-sm btn-primary w-100" type="button" @click="bulkActionUnits(port, 'status_id', 1)" v-show="selectedUnitTab < 4" :disabled="selectedUnitCount == 0 || (selectedUnitTab != 1 && voyage.cannot_permis)">
                {{ $t('operations.move_to_x', [ selectedUnitTabLabels[selectedUnitTab + 1] ])  }}&nbsp;&gt;
              </button>
            </div>
            <div class="col-1p5">
              <button class="btn btn-sm btn-primary w-100" type="button" @click="bulkActionUnits(port, 'is_priority')" :disabled="selectedUnitCount == 0">
                {{ $t('operations.status_priority')  }}
              </button>
            </div>
            <div class="col-1p5">
              <button class="btn btn-sm btn-primary w-100" type="button" @click="bulkActionUnits(port, 'is_cleared')" :disabled="selectedUnitCount == 0">
                {{ $t('operations.status_cleared')  }}
              </button>
            </div>
            <div class="col-1p5">
              <button class="btn btn-sm btn-primary w-100" type="button" @click="bulkActionUnits(port, 'is_on_hold')" :disabled="selectedUnitCount == 0">
                {{ $t('operations.status_on_hold')  }}
              </button>
            </div>
            <div class="col-1p5">
              <button class="btn btn-sm btn-primary w-100" type="button" @click="changeVoyageOpen(port)" :disabled="selectedUnitCount == 0 || selectedUnitTab == 4 || (voyage.cannot_permis && selectedUnitTab > 2)">
                {{ $t('operations.change_voyage')  }}
              </button>
            </div>
            <div class="col-1p5">
              <button class="btn btn-sm btn-primary w-100" type="button" @click="addRemarkOpen(port)" :disabled="selectedUnitCount == 0">
                {{ $t('form.remark')  }}
              </button>
            </div>
          </div>
        </div>

        <div class="unit-table-sp" id="main-table">
          <table class="w-100">
            <tr class="unit-table-header">
              <th class="col-form-label-sm sortable" :class="{'selected-header': sorters.description != 0}" style="max-width: 285px" @click="sort('description')">
                {{ $t('navigation.units') }} <span :data-sort="sorters.description">{{ Math.abs(sorters.description) }}</span>
              </th>
              <th class="col-form-label-sm" style="max-width: 50px"></th>
              <th class="col-form-label-sm sortable" :class="{'selected-header': sorters.commodity_name != 0}" style="max-width: 175px" @click="sort('commodity_name')">
                {{ $t('navigation.commodities') }} <span :data-sort="sorters.commodity_name">{{ Math.abs(sorters.commodity_name) }}</span>
              </th>
              <th class="col-form-label-sm sortable text-end" :class="{'selected-header': sorters.volume != 0}" style="max-width: 100px" @click="sort('volume')">
                <span :data-sort="sorters.volume">{{ Math.abs(sorters.volume) }}</span> <span class="">{{ $t('overview.measurement') }}</span>
              </th>
              <th class="col-form-label-sm sortable text-end" :class="{'selected-header': sorters.height != 0}" style="max-width: 100px" @click="sort('height')">
                <span :data-sort="sorters.height">{{ Math.abs(sorters.height) }}</span> <span class="">{{ $t('form.height') }}</span>
              </th>
              <th class="col-form-label-sm sortable" :class="{'selected-header': sorters.customer != 0}" style="max-width: 225px" @click="sort('customer')">
                {{ $t('navigation.customers') }} <span :data-sort="sorters.customer">{{ Math.abs(sorters.customer) }}</span>
              </th>
              <th class="col-form-label-sm sortable" :class="{'selected-header': sorters.vin != 0}" style="max-width: 225px" @click="sort('vin')">
                {{ $t('sales.vin') }} <span :data-sort="sorters.vin">{{ Math.abs(sorters.vin) }}</span>
              </th>
              <th class="col-form-label-sm sortable" :class="{'selected-header': sorters.job != 0}" style="max-width: 110px" @click="sort('job')">
                {{ $t('navigation.jobs') }} <span :data-sort="sorters.job">{{ Math.abs(sorters.job) }}</span>
              </th>
              <th class="col-form-label-sm sortable" :class="{'selected-header': sorters.delivered_date != 0}" style="max-width: 110px" @click="sort('delivered_date')">
                {{ $t('operations.date_delivered') }} <span :data-sort="sorters.delivered_date">{{ Math.abs(sorters.delivered_date) }}</span>
              </th>
            </tr>
            <tr v-for="unit, index in currentViewUnits" :key="index" :class="{'selected-unit' : unit.selected}" @mousedown="selectOnClick($event, unit, index)" @mouseenter.prevent="selectOnEnter(index)" v-show="unit.visible && !unit.inert">
              <td class="text-ellipsis" style="max-width: 285px">{{ unit.description }}</td>
              <td class="col-form-label-sm pe-1 text-end" style="max-width: 50px">
                <span class="unit-label bg-danger" v-show="unit.is_priority">P</span>
                <span class="unit-label bg-success" v-show="unit.is_cleared">C</span>
                <span class="unit-label bg-info" v-show="unit.is_on_hold">H</span>
                <span class="unit-label bg-secondary" v-show="unit.is_on_standby && unit.status == 3">S</span>
                <template v-if="unit.remarks">
                  <span class="unit-label hoverable bg-warning" @mouseenter="showTooltip" @mouseleave="hideTooltip">R</span>
                  <pre class="remark-blurb">{{unit.remarks}}</pre>
                </template>
              </td>
              <td class="text-ellipsis" style="max-width: 175px">{{ unit.commodity_name }}</td>
              <td class="text-ellipsis text-end pe-2" style="max-width: 100px">{{ getVolumeRounded(unit.volume) }} m<sup>2</sup></td>
              <td class="text-ellipsis text-end pe-2" style="max-width: 100px">{{ unit.height + ' cm' }}</td>
              <td class="text-ellipsis" style="max-width: 225px">{{ unit.customer }}</td>
              <td class="text-ellipsis" style="max-width: 225px"><a :href="'./jobs' + '?q=' + unit.id + '#' + unit.job_id" target="_blank">{{ unit.vin }}</a></td>
              <td class="text-ellipsis" style="max-width: 110px"><a :href="'./jobs#' + unit.job_id" target="_blank">{{ unit.job }}</a></td>
              <td class="text-ellipsis" style="max-width: 110px">{{ unit.delivered_date ? formatDate(unit.delivered_date, 'shortDate') : '-' }}</td>
            </tr>
          </table>
        </div>
        <div id="footer-bar" class="d-flex">
          <button type="button" class="btn btn-sm btn-light" @click="filter('priority')">
            <span class="unit-label bg-danger">P</span>&nbsp;{{$t('operations.status_priority')}}<i :class="checkFilter(filters.priority)"></i>
          </button>&nbsp;
          <button type="button" class="btn btn-sm btn-light" @click="filter('cleared')">
            <span class="unit-label bg-success">C</span>&nbsp;{{$t('operations.status_cleared')}}<i :class="checkFilter(filters.cleared)"></i>
          </button>&nbsp;
          <button type="button" class="btn btn-sm btn-light" @click="filter('on_hold')">
            <span class="unit-label bg-info">H</span>&nbsp;{{$t('operations.status_on_hold')}}<i :class="checkFilter(filters.on_hold)"></i>
          </button>&nbsp;
          <div class="btn-sm">
            <span class="unit-label bg-secondary">S</span>&nbsp;Stand-by
          </div>&emsp;
          <div class="btn-sm">
            <span class="unit-label bg-warning">R</span>&nbsp;{{$tc('form.remark', 2)}}
          </div>&emsp;
          |&emsp;
          <button type="button" class="btn btn-sm btn-light" @click="filter('delivered')">
            <span class="unit-label bg-dark">D</span>&nbsp;{{$t('operations.date_delivered')}}<i :class="checkFilter(filters.delivered)"></i>
          </button>&emsp;
          |&emsp;
          <FormItem v-model="filters.search" @onchange="filterRows" :placeholder="$t('overview.search')" />
        </div>
      </template>
      <div class="w-50" v-else>
        {{ $t('errors.no_units_for_voyage') }}
      </div>
    </div>
  </div>
  </template>
  <div v-else class="header-part-3 mt-5">
    <p>{{ $t('operations.actial_space_av', [(voyage.vessel.name)]) }}</p>
    <div class="row gap-5">
      <div class="col-auto" v-show="perCommodity" v-for="allocation in commgroups" :key="allocation.id">
        <div class="fw-bold">{{ allocation.name }} &ensp; <i :class="'clickable far fa-lock' + (checkClosing(port, allocation) ? '' : '-open')" @click="closingOpen(true, null, allocation)"></i></div>
        <meter min="0" max="0" value="0" low="0" high="0" optimum="0"></meter>
      </div>
      <div class="col-auto" v-show="!perCommodity">
        <div class="fw-bold">{{ $t('overview.total') }} &ensp; <i :class="'clickable far fa-lock' + (checkClosing(port, allocation) ? '' : '-open')" @click="closingOpen(true, null, null)"></i></div>
        <meter min="0" max="0" value="0" low="0" high="0" optimum="0"></meter>
      </div>
    </div>
    <br>
    {{ $t('errors.no_units_for_voyage') }}
  </div>

  <ClosingPopup :initialVoyage="voyage" :closingDat="closingData" :closingIsOpen="closingIsOpen" :ports="ports" :commgroups="commgroups" :canEdit="fullPermissions" />
  <AllocationPopup :totalSpace="totalAllocSpace" :totalMode="totalAllocMode" :allocIsOpen="allocIsOpen" :perCommodity="perCommodity" :perCompany="perCompany"
                   :selectedCompanies="selectedCompanies" :offices="offices" :commgroups="commgroups" :canEdit="fullPermissions" ref="allocForm" />
  <RemarkPopup :remarkIsOpen="remarkIsOpen" ref="remarkPopup" />
  <VoyagePlanningMoveParamPopup :moveParamIsOpen="moveParamIsOpen" :unitCount="selectedUnitCount" ref="moveParamPopup" />
  <ChangeVoyagePopup :changeVoyageIsOpen="changeVoyageIsOpen" ref="changeVoyagePopup" />

</template>

<script>
  import voyagePlanningService from '@/services/VoyagePlanningService';
  import commoditygroupService from '@/services/CommoditygroupService';
  import relationService from "@/services/RelationService"
  import portService from '@/services/PortService'
  import AllocationPopup from '@/components/voyage/VoyagePlanningAllocationPopup.vue'
  import ClosingPopup from '@/components/voyage/VoyagePlanningClosingPopup.vue'
  import RemarkPopup from '@/components/voyage/VoyagePlanningRemarkPopup.vue'
  import VoyagePlanningMoveParamPopup from '@/components/voyage/VoyagePlanningMoveParamPopup.vue'
  import ChangeVoyagePopup from '@/components/voyage/VoyagePlanningChangeVoyagePopup.vue'
  import store from '@/store/user'
  export default {
    props: ['itemData'],
    watch: {
      itemData: function (val) {
        this.voyage = val    
      },
    },
    components: {
      AllocationPopup,
      ClosingPopup,
      RemarkPopup,
      VoyagePlanningMoveParamPopup,
      ChangeVoyagePopup
    },
    data() {
      return {
        closingIsOpen: false,
        allocIsOpen: false,
        remarkIsOpen: false,
        moveParamIsOpen: false,
        changeVoyageIsOpen: false,
        perCommodity: false,
        perCompany: false,
        totalAllocSpace: null,
        totalAllocMode: 1,
        calculatedTotalSpace: 0,
        calculatedTotalCBMSpace: 0,
        calculatedTotalUnits: 0,
        showCBM: 0,
        selectedCompanies: [{id: 0, name: 'Complete voyage', value: '', mode: 1}],
        offices: [],
        ports: [],
        commgroups: [],
        hasCommgroups: false,
        closingData: {},
        allocData: {},
        chosenPortId: null,
        chosenPort: {pods: [], name: '-'},
        chosenDischarge: '',
        selectedUnitCount: 0,
        selectedUnitTab: 1,
        selectedUnitTabLabels: ['','','','','',''],
        prevSelectionIndex: null,
        initialSelectionIndex: null,
        selectionMin: 0,
        selectionMax: 0,
        filteredUnits: [],
        currentViewUnits: [],
        canSelect: false,
        extraParameter: null,
        selectionMode: false,
        filters: {'search': '', 'priority': null, 'cleared': null, 'delivered': null, 'on_hold': null},
        sorters: {'description': 0, 'commodity_name': 0, 'volume': 0, 'height': 0, 'customer': 0, 'vin': 0, 'job': 0, 'delivered_date': 0},
        activeSortRows: [],
        nextSortOrder: 1,
        hasUnits: true,
        fullPermissions: false,
        ownOfficeId: null,
        voyage: { ports: [] },
        currentlyLoading: "",
        fillVoyageStats: [],
        statuses: ['', 'waiting', 'firm', 'permis', 'loaded'],
        statusIds: [1, 'delivered', 2, 3, 4],
        mouseUpEvent: null,
        mouseDownEvent: null,
        dataItemsLoaded: 0,
        dataItemsToLoad: 3,
        formActive: false
      };
    },

    methods: {
      active(value){
          this.formActive = value;
      },
      validate(){
        return false;
      },
      getData() {
        return this.voyage
      },
      reset(setLabel = true){
        if(setLabel) document.getElementById('exampleModalLabel').children[0].innerHTML = '';
        this.filters = {'search': '', 'priority': null, 'cleared': null, 'delivered': null, 'on_hold': null};
        this.sorters = {'description': 0, 'commodity_name': 0, 'volume': 0, 'height': 0, 'customer': 0, 'vin': 0, 'job': 0, 'delivered_date': 0};
        this.showCBM = 0;
      },
      setData(data) {
        if(data){
          console.log(data);
          this.fullPermissions = data.permissions == 'all';
          this.voyage = data;
          this.closingIsOpen = false;
          this.allocIsOpen = false;
          const entries = Object.entries(this.voyage.ports);
          this.hasUnits = entries.length > 0;
          this.voyage.voyage_allocations.forEach(alloc => {
            if(alloc.per_commodity)
              this.perCommodity = true;
            if(alloc.per_company)
              this.perCompany = true;
          });
          if(this.voyage.voyage_allocations.length == 0){
            this.perCommodity = true;
            this.perCompany = false;
          }
          this.voyage.cannot_permis = data.permissions == 'limited' && !this.voyage.voyage_allocations.find(alloc => alloc.office_id == this.ownOfficeId);

          if(!this.hasUnits){
            this.$nextTick(() => {
              document.getElementById('exampleModalLabel').children[0].innerHTML = this.voyage.name;
            });
            this.loadData();
            return;
          }

          this.chosenPortId = entries[0][0];
          this.chosenPort = this.voyage.ports[this.chosenPortId];
          this.voyage.units = {};

          for(let lport in this.voyage.ports){
            this.voyage.ports[lport].name_units = this.voyage.ports[lport].name + ' (' + this.voyage.ports[lport].unit_count + ')';
            for(let dport in this.voyage.ports[lport].pods){
              const thisport = this.voyage.ports[lport].pods[dport];
              if(thisport.allocation === undefined)
                thisport.allocation = [];
            }
          }

          const entries2 = Object.entries(this.chosenPort.pods);
          this.$nextTick(() => {
            document.getElementById('exampleModalLabel').children[0].innerHTML = this.voyage.name;
            this.$nextTick(() => {
              this.selectedUnitTabLabel = this.selectedUnitTabLabels[2];
              this.chosenDischarge = entries2[0][0];
              this.currentlyLoading = (this.chosenPort.id + '-' + this.chosenPort.pods[this.chosenDischarge].id);
              const polcode = this.chosenPort.code;
              const podcode = this.chosenPort.pods[this.chosenDischarge].code;
              this.voyage.units[polcode] = {};
              this.voyage.units[polcode][podcode] = [];
              this.setUnitData(data.filtered_units, polcode, podcode);
              this.currentViewUnits = this.voyage.units[this.chosenPortId][this.chosenDischarge].filter(item => item.status == this.selectedUnitTab);
              const el = document.querySelector('#myTab button');
              if(el) el.click();
              this.currentlyLoading = "";
            });
          });
        }
        this.loadData();

        window.activeChannel = `return-voyage-planner-${this.voyage.id}`;
        window.Echo.channel(window.activeChannel)
          .listen('.listen-voyage-planner', (e) => {
            const pol = {...this.chosenPort};
            const pod = {...this.chosenPort.pods[this.chosenDischarge]};
            const selectedBefore = this.getData().units[pol.code][pod.code];
            const unitsBefore = this.getData().units;
            voyagePlanningService.getUnits(this.voyage.id, pol.id, pod.id, true).then(response => {
              this.voyage = response.data
              this.selectedUnitCount = 0;
              this.voyage.units = unitsBefore;
              this.currentlyLoading = "";
              this.setPortAllocations();
              this.voyage.units[pol.code][pod.code] = [];
              this.setUnitData(response.data.filtered_units, pol.code, pod.code);
              this.checkData();
              this.voyage.units[pol.code][pod.code].forEach(unit => {
                const compUnit = selectedBefore.find(aunit => aunit.id == unit.id);
                if(compUnit && compUnit.status == unit.status && compUnit.selected){
                  unit.selected = true;
                  this.selectedUnitCount += 1;
                }
              });
              for(let lport in this.voyage.ports){
                this.voyage.ports[lport].name_units = this.voyage.ports[lport].name + ' (' + this.voyage.ports[lport].unit_count + ')';
              }
              this.sortAndFilterRows();
              this.currentViewUnits = this.voyage.units[this.chosenPortId][this.chosenDischarge].filter(item => item.status == this.selectedUnitTab);
              this.$forceUpdate();
            }).catch(error => {
              console.log('error', error)
            })
        });
      },
      getUnitData(pol, pod){
        if((pol.id + '-' + pod.id) == this.currentlyLoading) return;
        this.currentlyLoading = (pol.id + '-' + pod.id);
        voyagePlanningService.getUnits(this.voyage.id, pol.id, pod.id).then(response => {
          if(!this.voyage.units[pol.code]) this.voyage.units[pol.code] = {};
          this.voyage.units[pol.code][pod.code] = [];
          this.setUnitData(response.data, pol.code, pod.code);
          this.currentlyLoading = "";
          window.setTimeout(() => {
            const el = document.querySelector("#myTabContent > div.active.show #unitsTab button");
            if(el) el.click();
          }, 50);
        });
      },
      setUnitData(data, pol, pod){
        data.forEach(item => {
          if(item.amounts_only){
            this.voyage.units[pol][pod].push({
              volume: item.booked_measurement ? ((item.job_unit.booked_measurement.width * item.job_unit.booked_measurement.length) / 10000) : 0,
              height: item.booked_measurement ? item.job_unit.booked_measurement.height : 0,
              status: item.status_id ? item.status_id : 1,
              commodity_group_id: item.commodity_group_id,
              inert: true,
              own_office: false
            });
          }
          if (!item.job_unit){
            return;
          }
          this.voyage.units[pol][pod].push({
            description: item.job_unit.make ? item.job_unit.make.name + ' ' + item.job_unit.model.name : ' - ',
            job: item.job_unit.job ? item.job_unit.job.number : '-',
            job_id: item.job_unit.job ? item.job_unit.job.id : null,
            customer: item.job_unit.job ? item.job_unit.job.customer.relation.name : '-',
            customer_multiline: item.job_unit.job ? item.job_unit.job.customer.relation.name.replaceAll(" ", "\n") : "",
            vin: item.job_unit.chassis_number,
            delivered_date: item.job_unit.delivered_date,
            volume: item.job_unit.booked_measurement ? ((item.job_unit.booked_measurement.width * item.job_unit.booked_measurement.length) / 10000) : 0,
            height: item.job_unit.booked_measurement ? item.job_unit.booked_measurement.height : 0,
            status: item.status_id ? item.status_id : 1,
            commodity_group_id: item.job_unit.commodity ? item.job_unit.commodity.commodity_group_id : 1,
            commodity_name: item.job_unit.commodity ? item.job_unit.commodity.name : ' - ',
            is_on_standby: item.is_on_standby,
            selected: false,
            visible: true,
            inert: false,
            own_office: item.own_office ? 1 : 0,
            id: item.job_unit.id,
            booking_id: item.job_booking_voyage ? item.job_booking_voyage.job_booking_id : 0,
            voyage_unit_id: item.id,
            is_priority: item.is_priority ? true : false,
            is_cleared: item.is_cleared ? true : false,
            is_on_hold: item.is_on_hold ? true : false,
            is_delivered: item.job_unit.delivered_date ? true : false,
            remarks: item.job_unit.remarks ? item.job_unit.remarks.map(r => r.user.name + ":\n" + r.content).join("\n") : ''
          });
        });
      },
      saveClosing(){
        voyagePlanningService.updateOpenClosed(this.voyage.id, {'lines': this.voyage.voyage_port_commodity_statuses}).then(response => {
          console.log('OK closing', response);
        }).catch(error => {
          console.log('error', error);
        });
      },
      saveAlloc(){
        this.voyage.voyage_allocations = [];
        if(this.perCommodity){
          this.selectedCompanies.forEach((comp, compindex) => {
            this.commgroups.forEach(group => {
              if(!this.perCompany && !group.max_space){
                return;
              }
              this.voyage.voyage_allocations.push(
                {
                  "value": this.perCompany ? group.companies[compindex].value : group.max_space,
                  "per_commodity": true,
                  "per_company": this.perCompany && compindex != 0,
                  "unit_measurement_type_id": this.perCompany ? group.companies[compindex].mode : group.mode,
                  "commodity_id": group.id,
                  "office_id": this.perCompany ? (compindex == 0 ? null : comp.id) : null
                }
              );
            });
          });
        }
        else{
          this.selectedCompanies.forEach((comp, compindex) => {
            this.voyage.voyage_allocations.push(
              {
                "value": (this.perCompany && comp.value) ? comp.value : this.totalAllocSpace,
                "per_commodity": false,
                "per_company": this.perCompany && compindex == 0,
                "unit_measurement_type_id": this.perCompany ? comp.mode : this.totalAllocMode,
                "commodity_id": null,
                "office_id": this.perCompany ? (compindex == 0 ? null : comp.id) : null
              }
            );
            if(compindex == 0 && this.perCompany){
              this.totalAllocSpace = comp.value;
              this.totalAllocMode = comp.mode;
            }
          });
        }
        
        voyagePlanningService.updateAllocation(this.voyage.id, {'lines': this.voyage.voyage_allocations}).then(response => {
          this.calcAllocStats();
        }).catch(error => {
          console.log('error', error);
        });
        this.allocIsOpen = false;
      },
      createClosing(){
        //Check if already exists
        let closing = this.voyage.voyage_port_commodity_statuses.find(cl => (
          (this.closingData.commodity_group_id === null || cl.commodity_id === this.closingData.commodity_group_id) && 
          (this.closingData.port_of_loading_id === null || cl.port_of_loading_id === this.closingData.port_of_loading_id) &&
          (this.closingData.port_of_discharge_id === null || cl.port_of_discharge_id === this.closingData.port_of_discharge_id)) );
        if(closing){
          alert("Such a combination already exists.");
          return;
        }

        //Create new
        this.voyage.voyage_port_commodity_statuses.push({
          commodity_id: this.closingData.commodity_group_id,
          port_of_loading_id: this.closingData.port_of_loading_id,
          port_of_discharge_id: this.closingData.port_of_discharge_id,
          status: this.closingData.status == 1 ? 'open' : 'closed',
          is_closed: this.closingData.status == 1 ? 0 : 1
        });
        this.closingData = {port_of_loading_code: null, port_of_loading_id: null, port_of_discharge_id: null, commodity_group_id: null, status: 1};
        this.saveClosing();
        this.$forceUpdate();
      },
      removeClosing(alloc){
        const name = this.safeFind(this.commgroups, 'id', alloc.commodity_id, 'name');
        if(confirm(this.$t('form.are_you_sure_delete') + ' ' + name + '?')){
          let closingIndex = this.voyage.voyage_port_commodity_statuses.findIndex(cl => cl.commodity_id == alloc.commodity_id && cl.port_of_loading_id == alloc.port_of_loading_id && cl.port_of_discharge_id == alloc.port_of_discharge_id);
          if(closingIndex >= 0){
            this.voyage.voyage_port_commodity_statuses.splice(closingIndex, 1);
          }
          alloc.status = 1;
          alloc.chosen = false;
          this.saveClosing();
          this.$forceUpdate();
        }
      },
      checkClosing(pod, comm){
        if(comm == null) return false;
        let closing = this.voyage.voyage_port_commodity_statuses.find(cl => (
          (cl.commodity_id == null || cl.commodity_id == comm.id) && 
          (cl.port_of_loading_id == null || cl.port_of_loading_id == this.chosenPort.id) &&
          (cl.port_of_discharge_id == null ||cl.port_of_discharge_id == pod.id)) );
        return closing ? (closing.status == 'closed' || closing.status == 2) : false;
      },
      calcSpace(alloc, percentage){
        if(this.voyage.voyage_allocations.length == 0) return 300;
        if(this.perCommodity){
          if(alloc == null) return 0;
          const group = this.voyage.voyage_allocations.find(gr => (gr.commodity_id == alloc.commodity_id && gr.office_id == null));
          if(!group) return 0;
          return group.value ? (group.value * percentage) : 300;
        }
        return (this.totalAllocSpace ? this.totalAllocSpace : 100) * percentage;
      },
      calcAllocation(alloc, withSelectedUnits = false){
        if(this.voyage.voyage_allocations.length == 0) return 0;
        let mode = this.totalAllocMode ? this.totalAllocMode : 1;
        let units = withSelectedUnits ? this.currentViewUnits.filter(unit => unit.selected) : [];
        if(this.perCommodity){
          if(alloc == null) return 0;
          const group = this.commgroups.find(gr => gr.id == alloc.commodity_id);
          units = units.filter(unit => unit.commodity_group_id == alloc.commodity_id);
          if(!group)
            return 0;
          if(group.mode)
            mode = group.mode;
        }
        let extra = units.reduce((acc, unit) => { return acc + (mode == 1 ? unit.volume : (mode == 2 ? 1 : (unit.volume * (unit.height / 100)))) }, 0);
        if(!this.perCommodity){
          if(!this.totalAllocSpace) return 0;
          return (mode == 1 ? this.calculatedTotalSpace : (mode == 2 ? this.calculatedTotalUnits : this.calculatedTotalCBMSpace)) + extra;
        }
        return (mode == 1 ? alloc.space_filled : (mode == 2 ? alloc.units_filled : alloc.cbm_filled)) + extra;
      },
      addCompany(e){
        let company = this.offices.find(off => off.id == e.target.value);
        if(company){
          company.is_chosen = true;
          company.value = '';
          company.mode = 1;
          this.selectedCompanies.push(company);
          if(this.perCommodity){
            this.commgroups.forEach(cg => {
              cg.companies.push(this.hardCopy(company));
            })
          }
        }
        this.$nextTick(() => {
          this.$refs.allocForm.defaultOffice = null;
        });
      },
      removeCompany(index, company){
        if(confirm(this.$t('form.are_you_sure_delete') + ' ' + company.name + '?')){
          company.is_chosen = false;
          this.selectedCompanies.splice(index, 1);
          if(this.perCommodity){
            this.commgroups.forEach(cg => {
              cg.companies.splice(index, 1);
            })
          }
          document.getElementById('ctab-0').click();
        }
      },
      setPolCode(e){
        this.closingData.port_of_loading_code = this.safeFind(this.ports, "id", e.target.value, "code");
      },
      setPort(){
        this.chosenPort = this.voyage.ports[this.chosenPortId]
        this.unselectUnits();
        this.calcAllocStats()
        this.$nextTick(() => {
            const el = document.querySelector('#myTab button');
            if(el){
              el.click();
              const discharge = el.firstElementChild.innerHTML;
              this.getUnitData(this.chosenPort, this.chosenPort.pods[discharge]);
            }
        });
      },
      setUnitTab(tab){
        this.selectedUnitTab = (tab + 1);
        this.unselectUnits();
        this.currentViewUnits = this.voyage.units[this.chosenPortId][this.chosenDischarge].filter(item => item.status == this.selectedUnitTab);
      },
      getVolumeRounded(rvolume, rheight = 0){
        let volume = rheight ? ((Number(rvolume) * Number(rheight)) / 100) : Number(rvolume);
        if(isNaN(volume)) return "-"; 
        const volstring = volume > 10000 ? volume.toFixed() : volume.toFixed(1).replace('.', ',');
        if(volstring.includes(',0')){
          return volstring.replace(',0', '');
        }
        return volstring;
      },
      selectOnClick(e, unit, index){
        this.filteredUnits = [...this.currentViewUnits];
        this.selectionMode = !unit.selected;
        unit.selected = !unit.selected;
        this.prevSelectionIndex = null;
        this.initialSelectionIndex = index;
        this.selectionMin = index;
        this.selectionMax = index;
        this.canSelect = true;
      },
      selectOnEnter(index){
        if(this.canSelect){
          const min = Math.min(this.initialSelectionIndex, index);
          const max = Math.max(this.initialSelectionIndex, index);
          this.selectionMin = Math.min(min, this.selectionMin);
          this.selectionMax = Math.max(max, this.selectionMax);
          for(let vindex = this.selectionMin; vindex < this.selectionMax; vindex++){
            let vunit = this.filteredUnits[vindex];
            if(vunit && vunit.visible && !vunit.inert){
              vunit.selected = vindex >= min && vindex <= max ? this.selectionMode : !this.selectionMode;
            }
          }
          this.prevSelectionIndex = index;
        }
      },
      unselectUnits(spol = null, spod = null){
        if(spol && spod){
          for(let unit of this.voyage.units[spol][spod]){
            unit.selected = false;
          }
        }
        else{
          for(let pol in this.voyage.units){
            for(let pod in this.voyage.units[pol]){
              for(let unit of this.voyage.units[pol][pod]){
                unit.selected = false;
                unit.visible = true;
              }
            }
          }
          this.reset(false);
        }
        this.selectedUnitCount = 0;
        this.filteredUnits = [];
      },
      selectAll(){
        let units = this.currentViewUnits;
        let alreadySelectedAll = true;
        for(let unit of units){
          if(unit.visible && !unit.selected && !unit.inert){
            alreadySelectedAll = false;
            break;
          }
        }
        let count = 0;
        units.forEach(unit => {
          if(alreadySelectedAll){
            unit.selected = false;
          }
          else{
            unit.selected = unit.visible && !unit.inert;
            count += (unit.visible && !unit.inert ? 1 : 0);
          }
        });
        this.selectedUnitCount = count;
      },
      bulkActionUnits(port, field, value = null, voyageInfo = null, extraRemarks = ''){
        let unitsToUpdate = [];
        let newValue = false;
        let dates = null;
        if(field == 'status_id'){
          const newStatus = this.voyage.units[this.chosenPortId][port.code].find(unit => unit.selected).status + value;
          if(!this.extraParameter && value === 1 && newStatus == 3){
            let allocationExceeded = false;
            if(!this.perCommodity){
              const currentAllocation = this.calcAllocation(null, true);
              const maxAllocation = this.calcSpace(null, 1);
              allocationExceeded = currentAllocation >= maxAllocation;
            }
            else{
              const commodityGroupIds = this.voyage.units[this.chosenPortId][port.code].filter(unit => unit.selected).map(unit => unit.commodity_group_id);
              const relevantAllocations = this.chosenPort.pods[port.code].allocation.filter(alloc => commodityGroupIds.includes(alloc.commodity_id));
              relevantAllocations.forEach(alloc => {
                const currentAllocation = this.calcAllocation(alloc, true);
                const maxAllocation = this.calcSpace(alloc, 1);
                if(currentAllocation >= maxAllocation){
                  allocationExceeded = true;
                }
              })
            }
            if(allocationExceeded){
              this.moveParamIsOpen = true;
              this.$refs.moveParamPopup.isMoveToLoading = false;
              this.$refs.moveParamPopup.defaultValue = 0;
              this.$refs.moveParamPopup.moveParam = null;
              this.$refs.moveParamPopup.port = port;
              return;
            }
          }
          if(this.extraParameter == 2 && value === 1 && newStatus == 3){
            this.voyage.units[this.chosenPortId][port.code].forEach(unit => unit.is_on_standby = 1);
          }
          if(!this.extraParameter && value === 1 && newStatus == 4){
            this.moveParamIsOpen = true;
            this.$refs.moveParamPopup.isMoveToLoading = true;
            let loadDatePlaceholder = null;
            this.voyage.units[this.chosenPortId][port.code].forEach(unit => {
              if(unit.selected && unit.loaded_date && (!loadDatePlaceholder || unit.loaded_date > loadDatePlaceholder)){
                loadDatePlaceholder = unit.loaded_date;
              }
            });
            this.$refs.moveParamPopup.defaultValue = loadDatePlaceholder;
            this.$refs.moveParamPopup.moveParam = '';
            this.$refs.moveParamPopup.port = port;
            return;
          }
          this.voyage.units[this.chosenPortId][port.code].forEach(unit => {
            if(unit.selected){
              unit.selected = false;
              this.updateStats(false, unit);
              unit.status = unit.status + value;
              this.updateStats(true, unit);
              unitsToUpdate.push(unit.voyage_unit_id);
              newValue = unit.status;
            }
          });
        }
        else if(field == 'remark'){
          newValue = value;
          this.voyage.units[this.chosenPortId][port.code].forEach(unit => {
            if(unit.selected){
              unit.selected = false;
              unitsToUpdate.push(unit.id);
              if(unit.remarks == ''){
                unit.remarks = newValue;
              }
              else{
                unit.remarks += ('\n\n' + newValue);
              }
            }
          });
          this.remarkIsOpen = false;
        }
        else if(field == 'voyage'){
          newValue = [];
          let bookingIds = [];
          dates = {'pol': voyageInfo.pol_date, 'pod': voyageInfo.pod_date};
          this.voyage.units[this.chosenPortId][port.code].forEach(unit => {
            if(unit.selected){
              bookingIds.push(unit.booking_id);
              const jobIndex = newValue.findIndex(j => j.job == unit.job_id);
              this.updateStats(false, unit, true);
              if(jobIndex === -1){
                let newValueRow = {'voyage': value, 'remarks': extraRemarks, 'job': unit.job_id, 'units': [], 'units_voyage': [], 'booking_ids': [], 'split_booking': false};
                newValueRow.units.push(unit.id);
                newValueRow.units_voyage.push(unit.voyage_unit_id);
                newValueRow.booking_ids.push(unit.booking_id);
                newValue.push(newValueRow);
              }
              else{
                newValue[jobIndex].units.push(unit.id);
                newValue[jobIndex].units_voyage.push(unit.voyage_unit_id);
                if(!newValue[jobIndex].booking_ids.includes(unit.booking_id)){
                  newValue[jobIndex].booking_ids.push(unit.booking_id);
                }
              }
            }
          });
          const allUnitsInBooking = this.voyage.units[this.chosenPortId][port.code].filter(unit => bookingIds.includes(unit.booking_id));
          allUnitsInBooking.forEach(unit => {
            if(unit.selected){
              unit.selected = false;
              unit.shouldDelete = true;
            }
            else{
              const splitter = newValue.find(j => j.job == unit.job_id);
              if(splitter) splitter.split_booking = true;
            }
          });
          newValue.forEach(value => {
            if(!value.split_booking) value.split_booking = value.booking_ids.length > 1;
          });
          this.changeVoyageIsOpen = false;
        }
        else{
          this.voyage.units[this.chosenPortId][port.code].forEach(unit => {
            if(unit.selected && unit[field] === false){
              newValue = true;
            }
          });
          this.voyage.units[this.chosenPortId][port.code].forEach(unit => {
            if(unit.selected){
              unit.selected = false;
              unit.shouldDelete = true;
              unit[field] = newValue;
              unitsToUpdate.push(unit.voyage_unit_id);
            }
          });
        }
        this.selectedUnitCount = 0;
        voyagePlanningService.update({'field': field, 'new_value': newValue, 'voyage_id': this.voyage.id, 'units': unitsToUpdate, 'extra_parameter': this.extraParameter, 'dates': dates}).then(response => {
          this.extraParameter = null;
          if(field == 'voyage'){
            const size = this.voyage.units[this.chosenPortId][port.code].length - 1;
            for(let i = size; i >= 0; i--){
              if(this.voyage.units[this.chosenPortId][port.code][i].shouldDelete){
                this.voyage.units[this.chosenPortId][port.code].splice(i, 1);
              }
            }
          }
          this.calcAllocStats();
          this.$nextTick(() => {
            const el = document.querySelector("#myTabContent > div.active.show #unitsTab button.active");
            if(el) el.click();
          });
        }).catch(error => {
          this.toastError(error);
          console.log('error', error);
        });
      },
      addRemarkOpen(port, isOpen = true){
        this.remarkIsOpen = isOpen;
        this.$refs.remarkPopup.remark = '';
        this.$refs.remarkPopup.port = port;
        window.setTimeout(() => document.getElementById('remarktextarea').focus(), 100);
      },
      changeVoyageOpen(port, isOpen = true){
        this.changeVoyageIsOpen = isOpen;
        let jobId = null;
        if(isOpen){
          for(let unit of this.voyage.units[this.chosenPortId][port.code]){
              if(unit.selected){
                jobId = unit.job_id;
                break;
              }
          }
          this.$refs.changeVoyagePopup.reset(port, jobId);
        }
      },
      calcStats(){
        if(!this.hasCommgroups) return;
        this.fillVoyageStats = null;
        this.calculatedTotalSpace = 0;
        this.calculatedTotalUnits = 0;
        this.calculatedTotalCBMSpace = 0;

        for(let lport in this.voyage.ports){
          for(let dport in this.voyage.ports[lport].pods){
            for(let alloc of this.voyage.ports[lport].pods[dport].allocation){
              alloc.space_filled = 0;
              alloc.units_filled = 0;
              alloc.cbm_filled = 0;
            }
          }
          if(!this.fillVoyageStats){
            this.fillVoyageStats = this.hardCopy(this.voyage.ports[lport].totals);
            continue;
          }
          for(let stat in this.voyage.ports[lport].totals){
            for(let pair in this.voyage.ports[lport].totals[stat]){
              this.fillVoyageStats[stat][pair]['units'] += this.voyage.ports[lport].totals[stat][pair]['units'];
              this.fillVoyageStats[stat][pair]['volume'] += this.voyage.ports[lport].totals[stat][pair]['volume'];
              this.fillVoyageStats[stat][pair]['height'] += this.voyage.ports[lport].totals[stat][pair]['height'];
            }
          }
        }
        for(let stat in this.fillVoyageStats){
          if(stat == "total") continue;
          for(let pair in this.fillVoyageStats[stat]){
            this.calculatedTotalUnits += this.fillVoyageStats[stat][pair]['units'];
            this.calculatedTotalSpace += this.fillVoyageStats[stat][pair]['volume'];
            this.calculatedTotalCBMSpace += (this.fillVoyageStats[stat][pair]['volume'] * this.fillVoyageStats[stat][pair]['height']) / 100;
          }
        }
        
        this.calcAllocStats();
      },
      calcAllocStats(){
        for(let pol in this.voyage.units){
          for(let pod in this.voyage.units[pol]){
            this.voyage.ports[pol].pods[pod].allocation.forEach(a => {a.space_filled = 0, a.cbm_filled = 0, a.units_filled = 0} );
            for(let unit of this.voyage.units[pol][pod]){
              const alloc = this.voyage.ports[pol].pods[pod].allocation.find(a => a.id == unit.commodity_group_id);
              const volume = Number(unit.volume);
              const height = Number(unit.height);
              if(!isNaN(volume)){
                if(alloc && unit.status > 2 && (!this.perCompany || unit.own_office)){
                  alloc.space_filled += volume;
                  alloc.cbm_filled += (volume * (isNaN(height) ? 0 : height)) / 100;
                }
              }
              if(alloc && unit.status > 2){
                alloc.units_filled += 1;
              }
            }
          }
        }
      },
      updateStats(mode, unit, removeOnly = false){
        const status = unit.status == 1 && unit.delivered_date ? 'delivered' : unit.status;
        if(removeOnly && unit.delivered_date && unit.status == 1)
          this.updateStatsStep(-1, unit, 'delivered');
        else
          this.updateStatsStep(mode ? 1 : -1, unit, status);
      },
      updateStatsStep(multiplier, unit, status){
        this.chosenPort.totals[unit.commodity_group_id][status]['units'] += multiplier;
        this.chosenPort.totals[unit.commodity_group_id][status]['volume'] += (unit.volume * multiplier);
        this.chosenPort.totals.total[status]['units'] += multiplier;
        this.chosenPort.totals.total[status]['volume'] += (unit.volume * multiplier);
        this.fillVoyageStats[unit.commodity_group_id][status]['units'] += multiplier;
        this.fillVoyageStats[unit.commodity_group_id][status]['volume'] += (unit.volume * multiplier);
        this.fillVoyageStats[unit.commodity_group_id][status]['height'] += (Number(unit.height) * multiplier);
        this.fillVoyageStats.total[status]['units'] += multiplier;
        this.fillVoyageStats.total[status]['volume'] += (unit.volume * multiplier);
        this.fillVoyageStats.total[status]['height'] += (Number(unit.height) * multiplier);
      },
      checkIfAlreadyChosen(option){
        return this.selectedCompanies.findIndex(comp => comp.id == option.id) >= 0;
      },
      closingOpen(isOpen, dport = null, alloc = null){
        if(isOpen && this.fullPermissions)
          this.closingData = {
            port_of_loading_code: this.chosenPortId, 
            port_of_loading_id: this.chosenPort.id ? this.chosenPort.id : null, 
            port_of_discharge_id: dport ? dport.id : null, 
            commodity_group_id: alloc ? alloc.id : null, 
            status: alloc ? (alloc.status == 'open' ? 1 : 2) : 1
          };
        this.closingIsOpen = isOpen;
      },
      closingOpenDefault(){
        this.closingData = {
          port_of_loading_code: null, 
          port_of_loading_id:  null, 
          port_of_discharge_id: null, 
          commodity_group_id: null, 
          status: 2
        };
        this.closingIsOpen = true;
      },
      allocOpen(isOpen){
        this.allocData = {};
        this.allocIsOpen = isOpen;
        if(isOpen){
          this.setCompany();
        }
      },
      setCompany(setTab = true, setCompanies = false){
        if(this.perCompany && setTab){
          this.$nextTick(() => {
            document.getElementById('ctab-0').click();
          })
        }
        if(setCompanies && this.perCompany && this.perCommodity){
          this.commgroups.forEach(cg => {
            let newComps = this.hardCopy(this.selectedCompanies);
            newComps.forEach(newComp => {
              newComp.value = cg.max_space;
              newComp.mode = cg.mode;
            });
            cg.companies = newComps;
          })
        }
      },

      loadData(){
        relationService.index(null, null, 'office-branches').then(response => {
          this.offices = [];
          response.data.forEach(dat => {
            this.offices.push({
              id: dat.office.id,
              name: dat.name
            })
          });
          this.dataItemsLoaded++;
          this.checkData();
        }).catch(error => {
          console.log('error', error)
        });
        portService.index().then(response => {
          this.ports = response.data;
          this.dataItemsLoaded++;
          this.checkData();
        }).catch(error => {
            console.log('error', error)
        });
        commoditygroupService.index().then(response => {
          this.commgroups = response.data;
          this.hasCommgroups = true;
          this.setPortAllocations();
          this.dataItemsLoaded++;
          this.checkData();
        }).catch(error => {
          console.log('error', error)
        });
      },
      setPortAllocations(){
        for(let lport in this.voyage.ports){
          for(let dport in this.voyage.ports[lport].pods){
            const thisport = this.voyage.ports[lport].pods[dport];
            if(!thisport.allocation){
              thisport.allocation = [];
            }
            if(thisport.allocation.length == 0){
              this.commgroups.forEach(group => {
                thisport.allocation.push({
                  id: group.id,
                  commodity_id: group.id,
                  port_of_discharge_id: null, 
                  port_of_loading_id: null,
                  name: group.name,
                  space_filled: 0,
                  units_filled: 0,
                  cbm_filled: 0,
                  status: 1,
                  chosen: false
                })
                
                if(group.mode == undefined){
                  group.mode = 1;
                }
                group.max_space = null;
                group.companies = [];
              });
            }
          }
        }
        if(!this.hasUnits){
          this.commgroups.forEach(group => {
            if(group.mode == undefined){
              group.mode = 1;
            }
            group.max_space = null;
            group.companies = [];
          });
        }
      },
      showTooltip(e){
        var tooltip = e.target.nextElementSibling;
        tooltip.classList.add('show');
        var x = 8;
        var ele = e.target;
        var checkEle = e.target;
        var setEle = true;
        while(checkEle.offsetParent !== null){
            x += checkEle.offsetLeft;
            checkEle = checkEle.offsetParent;
            if(setEle){
              ele = ele.offsetParent;
              if(ele.id == 'main-table'){
                setEle = false;
              }
            }
        }
        tooltip.style.left = (x - (tooltip.scrollWidth / 2)) + 'px';
        tooltip.style.transform = 'translateY(-' + (tooltip.scrollHeight + ele.scrollTop + 20) + 'px)';
      },
      hideTooltip(e){
        e.target.nextElementSibling.classList.remove('show');
      },
      filter(column, value = null){
        if(this.filters[column] === null){
          this.filters[column] = true;
        }
        else if(this.filters[column] === true || this.filters[column] === 1){
          this.filters[column] = false;
        }
        else{
          this.filters[column] = null;
        }
        this.sortAndFilterRows(false, true);
        this.unselectUnits(this.chosenPortId, this.chosenDischarge);
        this.$forceUpdate();
      },
      sort(column){
        const order = this.sorters[column];
        if(order === 0){
          this.sorters[column] = this.nextSortOrder;
          this.nextSortOrder++;
        }
        else if(order > 0){
          this.sorters[column] = order * -1;
        }
        else{
          const originalValue = Math.abs(order);
          this.sorters[column] = 0;
          this.nextSortOrder--;
          for(let sorter in this.sorters){
            if(this.sorters[sorter] != 0 && Math.abs(this.sorters[sorter]) > originalValue){
              this.sorters[sorter] = this.sorters[sorter] > 0 ? (this.sorters[sorter] - 1) : (this.sorters[sorter] + 1) ;
            }
          }
        }
        this.activeSortRows = [];
        for(let sorter in this.sorters){
          if(this.sorters[sorter] != 0){
            this.activeSortRows.splice(Math.abs(this.sorters[sorter]) - 1, 0, {name: sorter, order: this.sorters[sorter]});
          }
        }
        if(this.activeSortRows.length == 0){
          this.activeSortRows = [{name: 'id', order: 1}];
        }
        this.sortAndFilterRows(true, false);
      },
      filterRows(){
        this.sortAndFilterRows(false, true);
        this.unselectUnits(this.chosenPortId, this.chosenDischarge);
        this.$forceUpdate();
      },
      sortAndFilterRows(shouldSort = true, shouldFilter = true){
        const regex = new RegExp("^" + this.filters.search, "mi");
        const shouldFilterInputField = this.filters.search && this.filters.search.length >= 3;
        const sortLength = this.activeSortRows.length;
        for(let pol in this.voyage.units){
          for(let pod in this.voyage.units[pol]){
            if(shouldSort){
              this.voyage.units[pol][pod].sort((a, b) => {
                if(a.is_priority && !b.is_priority) return -1;
                if(b.is_priority && !a.is_priority) return 1;
                let result = 0;
                let i = 0;
                while(result == 0 && i < sortLength){
                  let field = this.activeSortRows[i].name;
                  let order = this.activeSortRows[i].order > 0 ? 1 : -1;
                  if(a[field] > b[field]){
                    result = order
                  }
                  else if(a[field] < b[field]){
                    result = -order
                  }
                  i++;
                }
                return result;
              });
            }
            if(!shouldFilter) continue;
            for(let unit of this.voyage.units[pol][pod]){
              unit.visible = (this.filters.priority === null || this.filters.priority === unit.is_priority) && 
                             (this.filters.cleared === null || this.filters.cleared === unit.is_cleared) && 
                             (this.filters.on_hold === null || this.filters.on_hold === unit.is_on_hold) && 
                             (this.filters.delivered === null || this.filters.delivered === unit.is_delivered);
              if(unit.visible && !unit.inert && shouldFilterInputField){
                unit.visible = regex.test(unit.description + '\n' + unit.commodity_name + '\n' + unit.customer_multiline + '\n' + unit.vin + '\n' + unit.job + '\n' + unit.job.substring(1));
              }
            }
          }
        }
      },
      checkFilter(value){
        return 'fa ps-2 pe-1 ' + (value === null ? 'fa-check invisible' : (value ? 'fa-check text-success' : 'fa-close text-danger'));
      },
      checkData(){
        if(this.dataItemsLoaded >= this.dataItemsToLoad){
          let prevCompany = 0;
          let companyIndex = -1;
          this.voyage.voyage_allocations.forEach(alloc => {
            if(this.perCompany){
              if(this.perCommodity){
                const commIndex = this.commgroups.findIndex(cg => cg.id == alloc.commodity_id);
                if(commIndex >= 0){
                  if(alloc.office_id === null){
                    this.commgroups[commIndex].max_space = alloc.value;
                    this.commgroups[commIndex].mode = alloc.unit_measurement_type_id;
                  }
                  if(alloc.office_id !== prevCompany){
                    companyIndex += 1;
                    let company = this.offices.find(cm => cm.id == alloc.office_id);
                    if(company){
                      company.value = alloc.value;
                      company.mode = alloc.unit_measurement_type_id;
                      this.selectedCompanies.push(company);
                    }
                  }
                  else{
                    this.selectedCompanies[companyIndex].value = alloc.value;
                    this.selectedCompanies[companyIndex].mode = alloc.unit_measurement_type_id;
                  }
                  this.commgroups[commIndex].companies.push({
                    value: alloc.value,
                    mode: alloc.unit_measurement_type_id
                  });
                  prevCompany = alloc.office_id;
                }
              }
              else{
                if(alloc.office_id){
                  let company = this.offices.find(cm => cm.id == alloc.office_id);
                  company.value = alloc.value;
                  company.mode = alloc.unit_measurement_type_id;
                  if(company){
                    this.selectedCompanies.push(company);
                  }
                }
                else{
                  this.selectedCompanies[0].value = alloc.value;
                  this.selectedCompanies[0].mode = alloc.unit_measurement_type_id;
                  this.totalAllocSpace = alloc.value;
                  this.totalAllocMode = alloc.unit_measurement_type_id;
                }
              }
            }
            else if(this.perCommodity){
              const commIndex = this.commgroups.findIndex(cg => cg.id == alloc.commodity_id);
              if(commIndex >= 0){
                this.commgroups[commIndex].max_space = alloc.value;
                this.commgroups[commIndex].mode = alloc.unit_measurement_type_id;
              }
            }
            else{
              this.totalAllocSpace = alloc.value;
              this.totalAllocMode = alloc.unit_measurement_type_id;
            }
          });
          this.calcStats();
        }
      },
      hardCopy(obj){
        return JSON.parse(JSON.stringify(obj));
      }
    },
    mounted(){
      this.selectedUnitTabLabels = ['', this.$t('overview.vp_waiting'), this.$t('overview.vp_firm'), this.$t('overview.vp_permis'), this.$t('operations.date_loaded'), '', '', ''];
      this.ownOfficeId = store.getters.getUser.office_id;
      this.mouseUpEvent = window.addEventListener('mouseup', () => {
        if(!this.canSelect) return;
        this.canSelect = false;
        if(this.filteredUnits[this.prevSelectionIndex])
          this.filteredUnits[this.prevSelectionIndex].selected = this.prevSelectionIndex == this.initialSelectionIndex ? !this.selectionMode : this.selectionMode;
        this.selectedUnitCount = this.filteredUnits.filter(u => u.visible && !u.inert && u.selected).length;
        this.prevSelectionIndex = null;
        this.initialSelectionIndex = null;
        this.filteredUnits = [];
      });
    },
    unmounted(){
      window.removeEventListener('mouseup', this.mouseUpEvent);
    }
  };
</script>

<style scoped>
.col-1p5{
  flex: 0 0 auto;
  width: 10%;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: .875rem;
}
.col-auto{
  min-width: 20%
}
.port-stats > div:not(:last-child){
  border-right: 1px solid #444
}
.port-stats span:first-child{
  display: inline-block;
  width:33%
}
.port-stats span:last-child{
  display: inline-block;
  width: 64%;
  text-align: end
}
.badge {
  background-color: #aaa;
  color: black;
  border-radius: 10em
}
.unit-label{
  color: white;
  border-radius: 4px;
  display: inline-block;
  width: 16px;
  text-align: center;
  font-size: 0.75rem;
  margin-right: 2px;
}
.hoverable{ cursor: pointer }
.remark-blurb{
  display: none;
  position: fixed;
  text-align: center;
  z-index: 1111;
  background-color: #333;
  color: white;
  border-radius: 4px;
  padding: 4px;
  max-width: 250px;
  font-family: unset;
  white-space: pre-line;
}
.remark-blurb.show{
  display: block !important;
}
.unit-table-sp{
  overflow: hidden scroll;
  position: relative;
  height: calc(max(100vh - 580px, 140px));
  user-select: none
}
th{
  position: sticky;
  top: 0;
  background-color: white;
  border-bottom: 1px solid #eee;
  z-index: 1
}
th > span{
  float: right;
  position: relative;
  right: 6px
}
.unit-table-sp td{
  padding: 3px 0 3px 0
}
.unit-table-sp tr:not(:last-child){
  border-bottom: 1px solid #eee;
}
.unit-table-sp tr:hover, .sortable:hover{
  background-color: #eee
}
.sortable:hover{
  cursor: pointer
}
.selected-unit, .selected-header{
  background-color: #cef !important
}
#footer-bar{
  background-color: white;
  width: 1370px;
  height: 3rem;
  align-items: center
}
#total-labels{
  text-align: end;
  padding-right: 0.5rem;
  position: absolute;
  top: 88px;
  left: calc(50% - 80px)
}
.port-stats > div > div:not(:first-child), #total-labels{
  font-size: 0.85rem
}
[data-sort="0"]{
  display: none
}
[data-sort="1"]::before, [data-sort="2"]::before, [data-sort="3"]::before, [data-sort="4"]::before, [data-sort="5"]::before, [data-sort="6"]::before, [data-sort="7"]::before, [data-sort="8"]::before{
  content: "\2193"
}
[data-sort="-1"]::before, [data-sort="-2"]::before, [data-sort="-3"]::before, [data-sort="-4"]::before, [data-sort="-5"]::before, [data-sort="-6"]::before, [data-sort="-7"]::before, [data-sort="-8"]::before{
  content: "\2191"
}
meter{
  --background: #e9ecef;
  --optimum: #198754;
  --sub-optimum: #ffc107;
  --sub-sub-optimum: #dc3545;

  background: var(--background);
  border: none;
  border-radius: .25rem;
  display: block;
  width: 100%
}
meter::-webkit-meter-bar{
  background: var(--background);
  border: none;
  border-radius: .25rem;
  height: 1rem
}
meter:-moz-meter-optimum::-moz-meter-bar { background: var(--optimum); }
meter::-webkit-meter-optimum-value { background: var(--optimum); }
meter[max="0"]:-moz-meter-optimum::-moz-meter-bar { background: var(--background) !important; }
meter[max="0"]::-webkit-meter-optimum-value { background: var(--background) !important; }
meter:-moz-meter-sub-optimum::-moz-meter-bar { background: var(--sub-optimum); }
meter::-webkit-meter-suboptimum-value { background: var(--sub-optimum); }
meter:-moz-meter-sub-sub-optimum::-moz-meter-bar { background: var(--sub-sub-optimum); }
meter::-webkit-meter-even-less-good-value { background: var(--sub-sub-optimum); }
</style>
<style>
#voyage-planning-popup .modal-body { overflow: hidden !important }
#voyage-planning-popup .modal-content:not(.adjustable) { min-height: 640px }
</style>